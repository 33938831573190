<template>
  <div class="container">
    <el-progress :percentage="progress" :format="format"></el-progress>
  </div>
</template>

<script>
export default {
  name: "nProgress",
  data(){
    return {
      progress: 0
    }
  },
  methods: {
    format(percentage) {
      return `${percentage}%`;
    },
    setProgress(progress){
      this.progress = progress
    }
  }
}
</script>

<style scoped lang="scss">
  @import "/src/style/element-variables";
  .container{

  }
  /deep/ .el-progress__text{
    color: $--color-primary;
    font-size: 13px!important;
  }
</style>