import {routes} from '@/router'
import {asyncRoutes} from '@/router/asyncRoutes'
import api from "/src/api"


/**
 * 通过meta.authority判断是否与当前用户权限匹配
 */
function hasPermission(authorities, route) {
  if (route.meta && route.meta.authority) {
    return authorities.indexOf(route.meta.authority) >= 0
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 */
function filterAsyncRouter(asyncRouterMap, authorities) {
  return asyncRouterMap.filter(route => {
    if (hasPermission(authorities, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, authorities)
      }
      return true
    }
    return false
  })

}

const state = ()=>({
  roles: [],
  menus: [],
  buttons: [],
  hasEditPassword: false
})

const mutations = {
  setRouters: (state, roles)=>{
    state.roles = roles
  },
  setMenus: (state, menus)=>{
    state.menus = menus
  },
  setButtons: (state, buttons)=>{
    state.buttons = buttons
  },
  setHasEditPassword: (state, status)=>{
    state.hasEditPassword = status
  },
}

const actions = {
  GenerateRoutes({ commit }, data) {
    return new Promise(resolve => {
      const { authorities } = data
      let accessedRouters = filterAsyncRouter(asyncRoutes, authorities)
      let lastRoutes = routes.concat(accessedRouters)
      lastRoutes.push({
        path: '*',
        name: "NotFound",
        component: ()=>import("@/views/noFound"),
        meta: { title: '404 NOT FOUND'},
        hidden: true
      })
      commit('setRouters', lastRoutes)
      resolve()
    })
  },
  GetRoles({ commit, dispatch }) {
    return new Promise(async resolve => {
      let res = await api.user.getUserInfo()
      if(!res){
        res = {
          roles: {
            menus: [],
            points: [],
          }
        }
      }
      commit("user/setUserInfo", {
        mobile: res.mobile,
        userId: res.userId,
        username: res.username,
        roleName: res.roleName,
        account: res.account,
      }, { root: true })

      let menus = Array.isArray(res.roles.menus)?res.roles.menus:[]
      // commit("setHasEditPassword", res.data.isInitialPassword == 0?false:true)
      commit("setMenus", menus)
      commit("setButtons", Array.isArray(res.roles.points)?res.roles.points:[])
      //
      resolve(menus)
    })
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}

