import request from "@/utils/requests"

function pageShop({page = 1, pageSize = 10, name, mobile, status, progress}){
    return request.request({
        url: "/shop/list",
        method: "get",
        params: {
            page,
            pageSize,
            name, mobile, status, progress
        }
    })
}

function getShop(id){
    return request.request({
        url: "/shop/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addShop(data){
    return request.request({
        url: "/shop/add",
        method: "post",
        data
    })
}

function editShop(data){
    return request.request({
        url: "/shop/edit",
        method: "post",
        data
    })
}

function partnerShop(id){
    return request.request({
        url: "/shop/partner",
        method: "get",
        params: {
            id
        }
    })
}

function changeShopProgress({id, progress, usci, lat, lng}){
    return request.request({
        url: "/shop/progress",
        method: "post",
        data: {
            id, progress, usci, lat, lng
        }
    })
}

function partnerTobeSelected({page= 1, pageSize= 10, type}){
    return request.request({
        url: "/shop/tobeSelected",
        method: "get",
        params: {
            page, pageSize, type
        }
    })
}

function deleteShop(id){
    return request.request({
        url: "/shop/delete",
        method: "delete",
        params: {
            id
        }
    })
}

function deletePartner(id){
    return request.request({
        url: "/shop/partner",
        method: "delete",
        params: {
            id
        }
    })
}

function addPartner({shop_id, auth_id}){
    return request.request({
        url: "/shop/partner",
        method: "post",
        data: {
            shop_id, auth_id
        }
    })
}

function getShopParams(){
    return request.request({
        url: "/shop/params",
        method: "get",
    })
}

function shopCheck({id, status, reason}){
    return request.request({
        url: "/shop/check",
        method: "post",
        data: {
            id, status, reason
        }
    })
}

function setFenRatio({id, fen_ratio}){
    return request.request({
        url: "/shop/fenRatio",
        method: "post",
        data: {
            id, fen_ratio
        }
    })
}

export default {
    pageShop,
    addShop,
    editShop,
    partnerShop,
    changeShopProgress,
    getShop,
    deleteShop,
    partnerTobeSelected,
    deletePartner,
    addPartner,
    getShopParams,
    shopCheck,
    setFenRatio

}
