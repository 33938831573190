import requests from "@/utils/requests";
// 角色相关

const getRoleList = function ({pageNum = 1, pageSize = 10}){
    return requests({
        url: `/role/page`,
        method: "post",
        data: {
            pageSize,
            pageNum,
        }
    })
}

const addRole = function ({name, description}){
    return requests({
        url: `/role`,
        method: "post",
        data: {
            name,
            description,
        }
    })
}

const editRole = function ({id ,name, description}){
    return requests({
        url: `/role`,
        method: "put",
        data: {
            id,
            name,
            description,
        }
    })
}

const assignRoles = function ({userId, rolesId= []}){
    return requests({
        url: `/user/role/assignRoles`,
        method: "post",
        data: {
            rolesId,
            userId
        }
    })
}

export default {
    assignRoles,
    getRoleList,
    addRole,
    editRole
}