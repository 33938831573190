import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    permission,
    user,
  },
  getters,
  state:{
    keepAlive: [],
    toggle: true
  },
  mutations:{
    setKeepAlive: (state, route)=>{
      if(state.keepAlive.indexOf(route.name) === -1){
        state.keepAlive.push(route.name)
      }
      if(route.matched.length >= 3){
        if(state.keepAlive.indexOf("parentLayout") === -1){
          state.keepAlive.push("parentLayout")
        }
      }
    },
    removeKeepAlive: (state, route)=>{
      let arr = []
      if(route.matched.length >= 3){
        arr = [route.name, "parentLayout"]
      }else{
        arr = [route.name]
      }
      state.keepAlive = state.keepAlive.filter(item=> arr.indexOf(item) === -1)
    },
    setToggle: (state)=>{
      state.toggle = !state.toggle
    },
  }
})

export default store
