<template>
  <div class="table">
    <slot name="header">
      <div class="header"></div>
    </slot>
    <div class="padding">
      <el-table
          :row-class-name="tableRowClassName"
          width="100%"
          :data="data"
          :max-height="tableHeight"
          ref="tableform"
          :row-key="rowKey"
          @expand-change="expandChange"
          :default-expand-all="expand"
          lazy
          :load="load"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      >
        <el-table-column
            :min-width="getWidth(item.width)"
            :label="item.label"
            :key="idx"
            :type="item.type"
            :fixed="item.fixed"
            v-for="(item, idx) in header"
        >
          <template slot-scope="scope">
            <slot
                v-if="item.slot"
                :name="item.prop"
                :row="scope.row"
                :$index="scope.$index"
            ></slot>
            <span class="padding9" style="display: inline-block" v-else>
              {{ item.func ? item.func(scope.row[item.key]) : scope.row[item.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          :total="total"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @getPageData="$listeners.getList"
          v-if="total > 0"
          ref="pagination">
      </pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "customTable",
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    rowKey: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    fixHeight:{
      type: Boolean,
      default: true
    },
    header: {
      type: Array,
      default: () => {
        return []
      }
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      emptyImg: require("@/assets/empty.png"),
      tableHeight: 999999999
    }
  },
  watch: {
    '$store.state.toggle': {
      handler() {
        if(this.fixHeight){
          this.setTableHeight()
        }
      },
      immediate: true
    },
    total: {
      handler(val) {
        if (val > 0 && this.fixHeight) {
          this.setTableHeight()
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    load(row, treeNode, resolve){
      this.$emit("load", row, treeNode, resolve)
    },
    getWidth(width) {
      let res = this.getElementWidth(width ? width : 0)
      return Number(res) ? Number(res) : 0
    },
    expandChange(row, expandedRows){
      this.$emit("expandChange", row, expandedRows)
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'statistics-row';
      } else {
        return '';
      }
    },
    setTableHeight() {
      this.$nextTick(() => {
        let top = this.$refs.tableform.$el.offsetTop
        let page = this.total > 0 ? this.getElementWidth(75) : this.getElementWidth(44)
        let height = document.body.clientHeight - top - Number(page)
        // console.log(top, page, height)
        this.tableHeight = height > 100 ? height : 100
      })
    },
  }
}
</script>

<style scoped lang="less">
.table {

  overflow: auto;

  .header {
    height: 10px;
  }

  .padding {
    padding: 0 20px 10px 20px;

    .empty {
      width: 294px;
      margin-top: 42px;;
    }
  }
}
</style>
<style lang="less">
@import "/src/style/style";
//修改element table 全局样式
// 滚动条
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}

.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px; /*滚动条的背景区域的圆角*/
  //background-color: rgba(249,250,252, 1); /*滚动条的背景颜色*/
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  //-webkit-box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3);
  background-color: rgba(22, 173, 235, 0.1); /*滚动条的背景颜色*/
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  border-radius: 10px; /*滚动条的圆角*/
  //-webkit-box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3);
  background-color: rgba(22, 173, 235, 1); /*滚动条的背景颜色*/
}

.el-scrollbar__wrap::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}


// th td
.el-table {
  th {
    font-size: 14px;
    font-weight: 550;
    color: @--color-primary;
    //border-bottom: 1px solid rgba(18, 154, 221, 0.45) !important;
    //border: none !important;
    background-color: @--table-th !important;
    box-sizing: border-box;

    div.cell {
      margin-left: 4px;
    }
  }

  td {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    box-sizing: border-box;
    padding: 9px 0 !important;
    border: none !important;

    div.cell {
      margin-left: 4px;
    }
  }

  //.el-table__row--striped td.el-table__cell{
  //  background-color: rgba(235,238,245, 0.3)!important;//1px solid #EBEEF5
  //}
}

.el-table::before, .el-table__fixed-right::before {
  height: 0 !important;
}

//斑马线
.el-table .statistics-row {
  background: rgba(249, 250, 252, 1) !important; //1px solid #EBEEF5
}

// 鼠标悬停背景色
.el-table__body tr:hover > td:not(.el-table__expanded-cell) {
  background-color: @--table-hover !important;
}

.el-table__body tr.hover-row > td.el-table__cell {
  background-color: @--table-hover !important;
}

.el-table__expanded-cell {
  .el-table {
    th {
      background-color: @--color-primary !important;
      border-bottom: 0px solid rgba(18, 154, 221, 0.45) !important;
      padding: 10px 0;
      color: white;
    }

    td {
      background-color: #EBF7FC !important;
      border-bottom: 1px solid #ECF2F6 !important;
    }
  }
}

.table {
  width: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.el-table__expand-icon{
  color: @--color-primary;
  //font-size: 16px;
  //font-weight: 800;
}

</style>
