import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index'
import {baseUrl} from "@/config/config";
import utils from "@/utils"
import router from "@/router";
import qs from "qs"

// create an axios instance
const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if(store.getters.token){
            config.headers['token'] = store.getters.token
        }

        if(config.method  === 'post' ){
            if(Object.prototype.toString.call( config.data) !== "[object FormData]"){
                config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
                config.data = qs.stringify(config.data);
            }
        }
        return config
    },
    error => {
        // do something with request error
        // console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    async response => {

        let res = response.data

        if(response.config.responseType === "blob"){
            if(response.headers["content-type"] === "application/json"){
                res = await utils.blob2String(res)
            }else {
                return Promise.resolve({
                    headers: response.headers,
                    data: response.data,
                })
            }
        }
        let [status, data] = execResult(res)
        if(!status){
            return Promise.reject(data)
        }else {
            return Promise.resolve(data)
        }

    },
    error => {
        store.commit("app/setLoading")
        if(error.response.status === 401){
            store.dispatch("user/asyncLogout", error.response.headers.location).then(r => {})
        }else {

            let message = error.message
            if(error.response.status === 408) message = "抱歉，系统繁忙，请稍后重试！"
            if(error.response.status === 413) message = "单个文件大小超出上传限制！"

            Message({
                message: message,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    }
)

const execResult = (res)=>{
    // if the custom code is not 20000, it is judged as an error.
    if (res.errno == 100004) { // 10002
        router.push({
            path: "/login"
        }).then(r => {})
    }else if (res.errno > 0 && res.errno != 100004) {
        let message = res.errmsg
        Message({
            message: message || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
    } else {
        return [true, res.data]
    }
    store.commit("app/setLoading")
    return [false, res.errmsg || "ERROR"]
}

export default service
