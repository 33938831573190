import requests from "/src/utils/requests"

const getPermissionList = function ({pageNum = 1, pageSize = 10000}){
    return requests({
        url: `/permission/page`,
        method: "post",
        data: {
            pageNum,
            pageSize,
        }
    })
}

const assignPermission = function ({roleId , permissionId = []}){
    return requests({
        url: `/role/permission/assignPermissions`,
        method: "post",
        data: {
            roleId,
            permissionIds: permissionId
        }
    })
}

const getRolePermission = function (id){
    return requests({
        url: `/admin/user/role`,
        method: "get",
        params: {
            id
        }
    })
}



export default {
    getPermissionList,
    assignPermission,
    getRolePermission
}