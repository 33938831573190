<template>
  <div class="pagination">
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pCurrentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pPageSize"
        layout="total, sizes, prev, pager, next"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
name: "pagination",
  props:{
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: Number,
  },
  data(){
    return {
      pCurrentPage: 1,
      pPageSize: 10
    }
  },
  created() {
  },
  watch:{
    pageSize: {
      handler(val){
          return this.pPageSize = val
      },
      immediate: true
    },
    currentPage: {
      handler(val){
        return this.pCurrentPage = val
      },
      immediate: true
    },
  },
  methods:{
    handleSizeChange(val) {
      this.pCurrentPage = 1
      this.pPageSize = val
      this.$emit("getPageData", this.pCurrentPage, this.pPageSize)
    },
    handleCurrentChange(val) {
      this.pCurrentPage = val
      this.$emit("getPageData", this.pCurrentPage, this.pPageSize)
    },
  }
}
</script>

<style scoped lang="less">
  .pagination{
    margin: 8px 0 0 auto;
  }
</style>
<style lang="less">
@import "/src/style/variables";
.el-pagination{
  width: 100%;
  font-weight: 300 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .el-pagination__total{
    flex: 1;
  }
  .el-pagination__sizes{
    order: 10;
    margin-right: 0;
    .el-select .el-input{
      margin-right: 0 !important;
    }
  }
  .el-pagination__jump{
    order: 20;
  }
}
.el-pager{
  font-family: PingFang SC, PingFangSC-Regular !important;
  .active{
    background: @--default-bg-color;
  }
  li{
    border-radius: 6px !important;
  }
}
.btn-prev,.btn-next{
  border-radius: 6px !important;
}
</style>