// import router, {resetRouter} from "/src/router"
import Cookies from "js-cookie"
import router from "@/router";

const state = ()=>({
  token: localStorage.getItem("token")?localStorage.getItem("token"):"",
  // token: Cookies.get("Admin-Token"),
  user: localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):{}
})

const mutations = {
  setToken: (state, token)=>{
    state.token = token
    localStorage.setItem("token", token)
  },
  setUserInfo: (state, user)=>{
    state.user = user
    localStorage.setItem("user", JSON.stringify(user))
  },
  logout: (state)=> {
    state.token = ""
    // state.user = {}
    sessionStorage.clear()
    localStorage.clear()
    Cookies.remove("Admin-Token")
    // localStorage.clear()
    // resetRouter()
  }
}

const actions = {
  asyncLogout({commit}, location){
    commit("permission/setButtons", [], { root: true })
    commit("permission/setMenus", [], { root: true })
    commit("permission/setRouters", [], { root: true })
    commit("logout")
    router.push({
      path: "/login"
    }).then(r => {})
  }

}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}

