import request from "@/utils/requests"

function pageShopTemplate({page = 1, pageSize = 10}){
    return request.request({
        url: "/shopTemplate/list",
        method: "get",
        params: {
            page,
            pageSize,
        }
    })
}

function getShopTemplate(id){
    return request.request({
        url: "/shopTemplate/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addShopTemplate({cover, path, name, type, sort, content, imgs, is_index}){
    return request.request({
        url: "/shopTemplate/add",
        method: "post",
        data: {
            cover, path, name, type, sort, content, imgs, is_index
        }
    })
}

function editShopTemplate({id, cover, path, name, type, sort, content, imgs, is_index}){
    return request.request({
        url: "/shopTemplate/edit",
        method: "post",
        data: {
            id, cover, path, name, type, sort, content, imgs, is_index
        }
    })
}

function pullShopTemplate(id){
    return request.request({
        url: "/shopTemplate/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushShopTemplate(id){
    return request.request({
        url: "/shopTemplate/push",
        method: "put",
        params: {
            id
        }
    })
}

function shopTemplateIndex({id, is_index}){
    return request.request({
        url: "/shopTemplate/index",
        method: "put",
        params: {
            id, is_index
        }
    })
}

function deleteShopTemplate(id){
    return request.request({
        url: "/shopTemplate/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageShopTemplate,
    addShopTemplate,
    editShopTemplate,
    pullShopTemplate,
    pushShopTemplate,
    getShopTemplate,
    deleteShopTemplate,
    shopTemplateIndex

}
