import router from './router'
import store from './store/index'
// import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import VueRouter from 'vue-router'

const filterHiddenMenu = (routes)=>{
    routes = JSON.parse(JSON.stringify(routes))
    // 过滤隐藏的菜单
    return routes.filter(item=> {
        if(item.hidden !== true){
            if(item.children && item.children.length > 0){
                item.children = filterHiddenMenu(item.children)
            }
            return true
        }else{
            return false
        }
    })
}

const whiteList = ['/login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    // NProgress.start()
    if(1){
        if(to.name === "Login"){
            next()
        }else {
            if (store.getters.roles.length === 0) {
                store.dispatch("permission/GetRoles").then(res=>{
                    store.dispatch("permission/GenerateRoutes", {authorities: res}).then(res=>{
                        //console.log("我只醒了")
                        router.options.routes = filterHiddenMenu(store.getters.roles)
                        router.matcher = new VueRouter().matcher // 过滤组件重名
                        store.getters.roles.forEach((item)=>{ // addRoutes 高版本废弃
                            router.addRoute(item)
                        })
                        next({ ...to, replace: true})
                    })
                })
            } else {
                next()
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }

})

router.afterEach(() => {
    // NProgress.done()
})