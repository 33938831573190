<template>
  <div class="search-box" v-if="searchData.length > 0">
    <div class="line-box" v-for="(item, index) in searchData" :key="index">
      <div :class="(index2 + 1 )%4 === 0 ? 'input':'input right20'" v-for="(item2, index2) in item" :key="item.key">
        <template v-if="item2.label">
          <div class="label">{{ item2.label }}: </div>
          <el-input
              v-if="item2.type === 'input'"
              v-model.trim="params[item2.key]"
              :placeholder="item2.placeholder?item2.placeholder:'请输入'" size="small"
              @keyup.enter.native="submitSearch"
              clearable
              @input="(val)=>{setParam(item2.key, val)}"
          >
          </el-input>
          <el-select
              v-else-if="item2.type === 'select'"
              v-model="params[item2.key]"
              :placeholder="item2.placeholder?item2.placeholder:'请输入'"
              size="small"
              clearable
              :multiple="item2.multiple"
              @change="(val)=>{setParam(item2.key, val, true)}"
          >
            <el-option
                v-for="option in item2.option"
                :key="option.value"
                :label="option.label"
                :value="option.value">
            </el-option>
          </el-select>
          <el-date-picker
              v-else-if="item2.type === 'date'"
              clearable
              v-model="params[item2.key]"
              :placeholder="item2.placeholder?item2.placeholder:'请输入'"
              size="small"
              @change="(val)=>{setParam(item2.key, val, true)}"
              :type="item2.option && item2.option.type?item2.option.type:'date'"
              value-format="timestamp"
          >
          </el-date-picker>
          <el-date-picker
              v-else-if="item2.type === 'daterange'"
              clearable
              v-model="params[item2.key]"
              :placeholder="item2.placeholder?item2.placeholder:'请输入'"
              :unlink-panels="true"
              size="small"
              @change="(val)=>{setParam(item2.key, val?val:[], true)}"
              type="daterange"
              range-separator="至"
              value-format="timestamp"
          >
          </el-date-picker>
          <el-cascader
              v-else-if="item2.type === 'cascader'"
              v-model="params[item2.key]"
              :placeholder="item2.placeholder?item2.placeholder:'请输入'"
              size="small"
              @change="(val)=>{setParam(item2.key, val, true)}"
              :options="item2.option"
              :props="{ multiple: !!item2.multiple, checkStrictly: !!item2.checkStrictly }"
              clearable>
          </el-cascader>
          <amount-range v-else-if="item2.type === 'amountrange'" v-model="params[item2.key]"
                        @keyup.enter.native="submitSearch" @input="(val)=>{setParam(item2.key, val)}"/>
        </template>
      </div>
      <div class="input" v-if="index === searchData.length - 1">
        <div class="search-button">
          <el-button type="default" size="small" @click="resetSearch" class="default-button">重 置</el-button>
          <el-button type="default" size="small" @click="exportFile" class="default-button" v-if="isDownload">导 出</el-button>
          <el-button type="primary" size="small" @click="submitSearch" class="default-button">查 询</el-button>
          <toggle-button @toggleHandler="toggleHandler" :default-is-stow="isStow" v-if="searchProps.length > 3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from "@/components/toggleButton";
import AmountRange from "@/components/amountRange";
import _ from "lodash"

export default {
  name: "searchGroup",
  components: {AmountRange, ToggleButton},
  watch: {
    value: {
      handler(val) {
        this.params = _.cloneDeep(val)
      },
      deep: true
    },
  },
  props: {
    searchProps: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchData: [],
      isStow: false,
      params: _.cloneDeep(this.value),
      initParams: _.cloneDeep(this.value),
      show: false,
      shake: null,
      shakeClear: null,
      exportFile: null
    }
  },
  created() {
    this.rebuildProps(this.searchProps)
    this.exportFile = this.$tool.throttle(async () => {
      this.$emit("download", () => {
        this.show = true
      })
    }, 1000)
  },
  methods: {
    rebuildProps(searchProps) {
      let searchData = this.$tool.splitArr(searchProps, 4)
      let last = searchData[searchData.length - 1]
      if (last.length <= 3) {
        last = last.concat(Array(3 - last.length).fill({}))
        searchData.splice(searchData.length - 1, 1, last)
      } else {
        last = Array(3).fill({})
        searchData.push(last)
      }
      this.searchData = searchData
    },
    toggleHandler(val) {
      if (val.isStow) {
        this.rebuildProps(this.searchProps.slice(0, 3))
      } else {
        this.rebuildProps(this.searchProps)
      }
      this.isStow = val.isStow
    },
    submitSearch(val) {
      this.$emit("search")
    },
    resetSearch() {
      this.$emit("input", _.cloneDeep(this.initParams))
      this.$emit("search")
    },
    setParam(key, val, isSearch = false) {
      this.params[key] = val
      this.$emit("input", _.cloneDeep(this.params))
      if (isSearch) {
        this.$emit("search")
      }
    },
    beforeEnter(el) {

    },
    enter(el, done) {
      this._offset = document.body.offsetHeight //激发重绘

      let downloadList = document.getElementById("download-list")
      let downButton = document.getElementById("down-button")
      let downloadListX = downloadList.getBoundingClientRect()
      let downButtonX = downButton.getBoundingClientRect()
      let x = downloadListX.left - downButtonX.left
      let y = downButtonX.top - downloadListX.top

      el.style.display = ''
      el.style.transform = `translateY(-${y}px)`

      let innerBall = el.querySelector('.innerBall')
      innerBall.style.transform = `translateX(${x}px)`
      innerBall.style.opacity = "0"
      el.addEventListener('transitionend', done)
    },
    afterEnter(el) {
      this.show = false
      el.style.display = 'none'

      this.setShake()

      el.style.transform = `translate(0, 0)`
      let innerBall = el.querySelector('.innerBall')
      innerBall.style.transform = `translate(0, 0)`
      innerBall.style.opacity = "1"

    },
    setShake() {
      clearInterval(this.shake)
      clearInterval(this.shakeClear)
      let f = 10
      this.shake = setInterval(() => {
        let downloadList = document.getElementById("download-list")
        downloadList.style.transform = `rotate(${f}deg)`
        downloadList.style.width = `24px`
        downloadList.style.height = `24px`
        f *= -1
      }, 150)
      this.shakeClear = setTimeout(() => {
        clearInterval(this.shake)
        let downloadList = document.getElementById("download-list")
        downloadList.style.transform = `rotate(0deg)`
        downloadList.style.width = `18px`
        downloadList.style.height = `18px`
      }, 2000)
    }
  }
}
</script>

<style scoped lang="less">

/deep/ .el-cascader__tags{
  max-height: 26px;
  overflow-y: auto;
}
/deep/ .el-cascader__tags::-webkit-scrollbar{
 display: none;
}
.down-button {
  margin: 0 10px;
  position: relative;
}

.ball {
  position: absolute;
  z-index: 100;
  top: 0;
}

.ball {
  width: 24px;
  height: 24px;
  transition: all 1s cubic-bezier(0.000, -0.190, 0.000, 0.970);
}

.innerBall {
  width: 24px;
  height: 24px;
  opacity: 1;

  svg {
    width: 24px;
    height: 24px;
  }

  transition: all 1s linear;
}
</style>
<style lang="less">

//条件搜索样式
.search-box {

  padding: 0 24px 10px 24px;
  border-radius: 10px;
  background-color: white;
  overflow: auto;
  margin-bottom: 16px;

  .line-box {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;

    .input {
      //display: flex;
      //flex-direction: column;
      flex: 1;
      position: relative;
      //margin: 0 20px;
      .label {
        white-space: nowrap;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #697B8C;
        text-align: left;
      }

      .el-input {
        width: 100%;
      }

      .el-date-editor, .el-range-editor {
        width: 100%;
      }
    }
  }


}

.search-button {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-top: 8px;
}

.el-select {
  width: 100%;
}

</style>
