import requests from "/src/utils/requests"

const getSensitiveList = function ({pageNum = 1, pageSize = 10, account=""}){
    return requests({
        url: `/gu/event/record/page`,
        method: "post",
        data: {
            pageNum,
            pageSize,
            account,
        }
    })
}

export default {
    getSensitiveList,
}