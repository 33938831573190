import request from "@/utils/requests"

function pageCategory({page = 1, pageSize = 10}){
    return request.request({
        url: "/category/list",
        method: "get",
        params: {
            page,
            pageSize,
        }
    })
}

function allCategory(){
    return request.request({
        url: "/category/all",
        method: "get",
    })
}

function getCategory(id){
    return request.request({
        url: "/category/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addCategory({name, sort}){
    return request.request({
        url: "/category/add",
        method: "post",
        data: {
            name, sort
        }
    })
}

function editCategory({id, name, sort}){
    return request.request({
        url: "/category/edit",
        method: "post",
        data: {
            id, name, sort
        }
    })
}

function pullCategory(id){
    return request.request({
        url: "/category/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushCategory(id){
    return request.request({
        url: "/category/push",
        method: "put",
        params: {
            id
        }
    })
}

function deleteCategory(id){
    return request.request({
        url: "/category/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageCategory,
    addCategory,
    editCategory,
    pullCategory,
    pushCategory,
    getCategory,
    deleteCategory,
    allCategory

}
