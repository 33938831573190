const getters = {
  menu: state => state.app.menu,
  clientBody: state => state.app.clientBody,
  basePath: state => state.app.basePath,
  secMenu: state => state.app.secMenu,
  isShrink: state => state.app.isShrink,
  isLoading: state => state.app.isLoading,
  isTask: state => state.app.isTask,
  taskList: state => state.app.taskList,
  token: state => state.user.token,
  user: state => state.user.user,
  hasEditPassword: state => state.permission.hasEditPassword,
  roles: state => state.permission.roles,
  permissionMenus: state => state.permission.menus,
  permissionButtons: state => state.permission.buttons,
}
export default getters
