import request from "@/utils/requests"

function pageSlider({page = 1, pageSize = 10}){
    return request.request({
        url: "/slider/list",
        method: "get",
        params: {
            page,
            pageSize,
        }
    })
}

function getSlider(id){
    return request.request({
        url: "/slider/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addSlider({img, path, name, type, sort, content, position}){
    return request.request({
        url: "/slider/add",
        method: "post",
        data: {
            img, path, name, type, sort, content, position
        }
    })
}

function editSlider({id, img, path, name, type, sort, content, position}){
    return request.request({
        url: "/slider/edit",
        method: "post",
        data: {
            id, img, path, name, type, sort, content, position
        }
    })
}

function pullSlider(id){
    return request.request({
        url: "/slider/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushSlider(id){
    return request.request({
        url: "/slider/push",
        method: "put",
        params: {
            id
        }
    })
}

function deleteSlider(id){
    return request.request({
        url: "/slider/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageSlider,
    addSlider,
    editSlider,
    pullSlider,
    pushSlider,
    getSlider,
    deleteSlider

}
