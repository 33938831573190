import Vue from "vue";
import router from "@/router";
import stores from "@/store";

const mixin = {
    data(){
      return {

      }
    },
    beforeRouteEnter(to, from, next){
        if(to.name.toLowerCase().indexOf("list") > -1){
            stores.commit("setKeepAlive", to)
        }
        next()
    },
    computed: {
        getElementWidth() {
            return (width) => {
                return width > 0 ? this.$tool.resetPixel(width) : 0
            }
        }
    },
    methods: {
        goBack() {
            router.go(-1)
            return false
        },
        addListenerScroll(containerList, callback, ref="main-container"){
            let container = this.$refs[ref]
            const containerRect = container.getBoundingClientRect()
            let activeIndex = 0
            container.addEventListener("scroll", function (e){
                const clientHeight = container.clientHeight;
                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight;
                if (clientHeight + scrollTop === scrollHeight) {
                    // console.log('竖向滚动条已经滚动到底部')
                    activeIndex = containerList.length - 1
                }else {
                    for (let i = 0; i < containerList.length; i++) {
                        let rect = document.getElementById(containerList[i]).getBoundingClientRect()
                        // console.log(containerList[i], rect.y, rect.height, containerRect.height, containerRect.top, rect.bottom)

                        if(rect.y > 0 && rect.y < containerRect.height || rect.y < 0 && rect.y + rect.height > containerRect.top){
                            activeIndex = i
                            break
                        }
                    }
                }
                callback(activeIndex)
            })
        }
    }
}

Vue.mixin(mixin)
