<template>
  <div class="toggle-button" @click="toggle">
    <el-button type="text" size="small" >{{ buttonName }}</el-button>
    <div class="svg">
      <svg-icon :icon-class="icon" />
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex"
export default {
  name: "toggleButton",
  props:{
    defaultIsStow: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      isStow: this.defaultIsStow, // 是否收起
      icon: "",
      buttonName: ""
    }
  },
  created() {
    if(this.isStow){
      this.buttonName = "展开"
      this.icon = "down"
    }else {
      this.buttonName = "收起"
      this.icon = "top"
    }
  },
  methods:{
    ...mapMutations(["setToggle"]),
    toggle(){
      //console.log(this.isStow)
      this.setToggle()
      if(this.isStow){
        this.isStow = false
        this.buttonName = "收起"
        this.icon = "top"
        this.$emit("toggleHandler", {isStow: this.isStow})
      }else {
        this.isStow = true
        this.buttonName = "展开"
        this.icon = "down"
        this.$emit("toggleHandler", {isStow: this.isStow})
      }
    }
  }
}
</script>

<style scoped lang="less">
.toggle-button{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  .svg{
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}
</style>