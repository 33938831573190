import request from "@/utils/requests"

function pageArticle({page = 1, pageSize = 10}){
    return request.request({
        url: "/article/list",
        method: "get",
        params: {
            page,
            pageSize,
        }
    })
}

function getArticle(id){
    return request.request({
        url: "/article/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addArticle({title, url, article_cate_id, cover, content, type, tag, sort}){
    return request.request({
        url: "/article/add",
        method: "post",
        data: {
            title, url, article_cate_id, cover, content, type, tag, sort
        }
    })
}

function editArticle({id, title, url, article_cate_id, cover, content, type, tag, sort}){
    return request.request({
        url: "/article/edit",
        method: "post",
        data: {
            id, title, url, article_cate_id, cover, content, type, tag, sort
        }
    })
}

function pullArticle(id){
    return request.request({
        url: "/article/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushArticle(id){
    return request.request({
        url: "/article/push",
        method: "put",
        params: {
            id
        }
    })
}

function deleteArticle(id){
    return request.request({
        url: "/article/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageArticle,
    addArticle,
    editArticle,
    pullArticle,
    pushArticle,
    getArticle,
    deleteArticle

}
