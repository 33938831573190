import user from "./user"
import common from "./common";
import slider from "./slider";
import menu from "./menu";
import notice from "./notice";
import article from "./article";
import category from "./category";
import shopTemplate from "./shopTemplate";
import partner from "./partner";
import customer from "./customer";
import shop from "./shop";

export default {
    user,
    common,

    slider,
    menu,
    notice,
    article,
    category,
    shopTemplate,
    partner,
    customer,
    shop

}
