<template>
  <div >
    <div class="span" v-for="(item, index) in num" :key="index"></div>
  </div>
</template>

<script>
export default {
  name: "loading",
  props:{
    type: {
      type: String,
      default: "rectangle"
    },
    num: {
      type: Array,
      default: ()=>{
        return [1,2,3,4,5]
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "style.less";

</style>