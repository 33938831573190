import api from "/src/api"

const state = ()=> ({
  menu: sessionStorage.getItem("menuStatus")?JSON.parse(sessionStorage.getItem("menuStatus")):[[], ""],
  isShrink: sessionStorage.getItem("isShrink")?sessionStorage.getItem("isShrink"):"",
  secMenu: [],
  basePath: "",
  isTask: false,
  taskList: [],
  isLoading: false,
  multi: true,
  channel: {},
  channelOption: [],
  clientBody: document.body.clientWidth
})

let getMenus = (menu = [], res = [])=> {
  menu.forEach(item=> {
    if(item.children && item.children.length > 0){
      getMenus(item.children, res)
    }else {
      res.push(item)
    }
  })
}

const mutations = {
  setMenuState: (state, menu)=>{
    sessionStorage.setItem("menuStatus", JSON.stringify(menu))
    state.menu = menu
  },
  toggleShrink: (state)=>{
    state.isShrink = state.isShrink?"":"1"
    sessionStorage.setItem("isShrink", state.isShrink)
  },
  toggleTask: (state)=>{
    state.isTask = !state.isTask
  },
  setSecMenu: (state, menu)=> {
    let menus = []
    getMenus(menu.menu, menus)
    state.secMenu = menus
    state.basePath = menu.basePath
  },
  setTaskList: (state, list)=> {
    state.taskList = list
  },
  setLoading: (state, status = false)=> {
    state.isLoading = status
  },
  //展开菜单
  showToggle(state, {node, secName}){
    let selectMenu = state.menu;
    let firstMenu = selectMenu[0]
    if(state.multi){
      if(firstMenu.indexOf(node.name) === -1){
        firstMenu.push(node.name)
      }
    }else {
      firstMenu = [node.name]
    }
    selectMenu.splice(0, 1, firstMenu)
    if(secName){
      selectMenu.splice(1, 1, secName)
    }
    sessionStorage.setItem("menuStatus", JSON.stringify(selectMenu))
    state.menu = selectMenu
  },
  // 收起菜单
  closeToggle(state, node){
    let selectMenu = state.menu;

    let firstMenu = selectMenu[0]
    firstMenu = firstMenu.filter(item=>{
      return item !== node.name
    })
    selectMenu.splice(0, 1, firstMenu)
    sessionStorage.setItem("menuStatus", JSON.stringify(selectMenu))
    state.menu = selectMenu
  },
  //
  setClientWidth: (state, width)=>{
    state.clientBody = width
  },
  setChannelState: (state, data)=> {
    let channelOption = []
    for(let i in data){
      //console.log(i)
      channelOption.push({
        label: data[i],
        value: i,
      })
    }
    state.channelOption = channelOption
    state.channel = data
  }
}

const actions = {
  async getTaskList({ commit }){
    let res = await api.task.getTask()
    commit("setTaskList", res.data)
  },
  async setChannel({ commit }){
    let res = await api.common.getChannel()
    commit("setChannelState", res.data)
  },

}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
