import utils from "./index"

let watermark = {}

let setWatermark = (text, sourceBody) => {
    let id = Math.random()*10000+'-'+Math.random()*10000+'/'+Math.random()*10000

    let elements = document.getElementsByClassName("masterMask")
    for(let i=0;i<elements.length;i++){
        elements[i].remove()
    }

    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id))
    }

    let can = document.createElement('canvas')
    can.width = 300
    can.height = 280

    let cans = can.getContext('2d')
    cans.rotate(-30 * Math.PI / 180)
    cans.font = 'normal normal 200 20px Vedana'
    cans.fillStyle = 'rgba(0,0,0,0.1)'
    cans.textAlign = 'left'
    cans.textBaseline = 'top'
    cans.fillText(text, 20, 80 )
    // cans.fillText(text, can.width / 2, can.height/4 )
    // cans.fillText(text, can.width / 4, can.height )

    let water_div = document.createElement('div')
    water_div.id = id
    water_div.className = "masterMask"
    water_div.style.pointerEvents = 'none'
    water_div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
    if(sourceBody){
        water_div.style.width = '100%'
        water_div.style.height = '100%'
        sourceBody.appendChild(water_div)
    }else{
        water_div.style.top = '3px'
        water_div.style.left = '0px'
        water_div.style.position = 'fixed'
        water_div.style.zIndex = '100000'
        water_div.style.width = document.documentElement.clientWidth  + 'px'
        water_div.style.height = document.documentElement.clientHeight  + 'px'
        document.body.appendChild(water_div)
    }

    return id
}

/**
 *  该方法只允许调用一次
 *  @param:
 *  @text == 水印内容
 *  @sourceBody == 水印添加在哪里，不传就是body
 * */
watermark.set = (text, sourceBody) => {
    let id = setWatermark(text, sourceBody)
    setInterval(() => {
        if (document.getElementById(id) === null) {
            id = setWatermark(text, sourceBody)
        }
    }, 2000)
    let reset = utils.fDou(()=>{
        setWatermark(text, sourceBody)
    }, 300)
    window.addEventListener('resize', () => {
        reset()
    })
}

export default watermark