import Vue from "vue"
import store from "@/store"
import ResizeObserver from "resize-observer-polyfill";

Vue.directive('has', {
    inserted: function (el, binding, vnode) {
        // 获取指令按钮权限
        let permission = binding.value;
        if(store.getters.permissionButtons.indexOf(permission) === -1 && store.getters.permissionMenus.indexOf(permission) === -1 ){
            el.parentNode.removeChild(el);
        }
    }
})

Vue.directive("resize", { // 指令的名称

    bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                binding.value(entry.contentRect);
            }
        });
        resizeObserver.observe(el)
    },
    unbind(el) {

    }
})