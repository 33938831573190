import store from "@/store"

// rem等比适配配置文件
// 基准大小
const baseSize = 15
// 设置 rem 函数
function setRem () {
    // 当前页面屏幕分辨率相对于 1440宽的缩放比例，可根据自己需要修改
    let width = document.body.clientWidth
    if(width < 1200){
        width = 1200;
    }else if(width > 1440){
        width = 1440
    }
    // width = 1440
    const scale =  width / 1440
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 5) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.addEventListener('resize', () => {
    setRem()
    store.commit("setToggle")
    store.commit("app/setClientWidth", document.body.clientWidth)
})