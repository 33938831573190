import request from "@/utils/requests"

function pageMenu({page = 1, pageSize = 10, position}){
    return request.request({
        url: "/menu/list",
        method: "get",
        params: {
            page,
            pageSize,
            position
        }
    })
}

function getMenu(id){
    return request.request({
        url: "/menu/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addMenu({icon, path, name, type, sort, content, position}){
    return request.request({
        url: "/menu/add",
        method: "post",
        data: {
            icon, path, name, type, sort, content, position
        }
    })
}

function editMenu({id, icon, path, name, type, sort, content, position}){
    return request.request({
        url: "/menu/edit",
        method: "post",
        data: {
            id, icon, path, name, type, sort, content, position
        }
    })
}

function pullMenu(id){
    return request.request({
        url: "/menu/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushMenu(id){
    return request.request({
        url: "/menu/push",
        method: "put",
        params: {
            id
        }
    })
}

function deleteMenu(id){
    return request.request({
        url: "/menu/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageMenu,
    addMenu,
    editMenu,
    pullMenu,
    pushMenu,
    getMenu,
    deleteMenu

}
