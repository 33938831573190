import request from "@/utils/requests"

function pagePartner({page = 1, pageSize = 10, username, start, end, type, invite, status}){
    return request.request({
        url: "/partner/list",
        method: "get",
        params: {
            page,
            pageSize, username, start, end, type, invite, status
        }
    })
}

function getPartner(id){
    return request.request({
        url: "/partner/detail",
        method: "get",
        params: {
            id
        }
    })
}

function checkPartner({id, status, reason}){
    return request.request({
        url: "/partner/check",
        method: "post",
        data: {
            id,
            status,
            reason
        }
    })
}

function setInvitePartner({id, pid}){
    return request.request({
        url: "/partner/setInvite",
        method: "post",
        data: {
            id,
            pid,
        }
    })
}

export default {
    pagePartner,
    getPartner,
    checkPartner,
    setInvitePartner

}
