<template>
  <div :class="isFocus?'range-box focus':'range-box'">
    <div style="color: rgb(192, 196, 204)">
      ￥
    </div>
    <el-input
        placeholder=""
        size="small"
        @focus="()=> {this.isFocus = true}"
        @blur="()=> {this.isFocus = false}"
        :value="value[0]"
        @input="(val)=> {setModel(0, val)}"
    ></el-input>
    <div class="fg">至</div>
    <el-input
        placeholder=""
        size="small"
        @focus="()=> {this.isFocus = true}"
        @blur="()=> {this.isFocus = false}"
        :value="value[1]"
        @input="(val)=> {setModel(1, val)}"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "amountRange",
  watch:{
  },
  props:{
    value: {
      type: Array,
      default: ()=>[null, null]
    }
  },
  data(){
    return {
      isFocus: false,
    }
  },
  computed:{
  },
  methods: {
    formatAmt(val){
      if(parseFloat(val) === parseFloat(val)){
        val = parseFloat(val)
      } else {
        val = null
      }
      return val
    },
    setModel(index, e){
      this.value.splice(index, 1, e)
      this.$emit("input", this.value.map(item=>this.formatAmt(item)))
    }
  },
  created() {
  }
}
</script>

<style scoped lang="less">
 @import "/src/style/variables";
  .focus{
    border: 1px solid @--color-primary !important;
  }
  .range-box{
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 14px;
    color: rgb(192, 196, 204);
    border: 1px solid #DCDFE6;

    font-size: 14px;
    /deep/ input{
      border: none;
      text-align: center;
    }
  }
</style>
<style lang="less">
 .el-range-separator{
  color: rgb(192, 196, 204) !important;
}
</style>