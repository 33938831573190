import pagination from "@/components/pagination";
import nProgress from "@/components/nProgress";
import loading from "@/components/loading/loading";
import customTable from "@/components/customTable";
import searchGroup from "@/components/searchGroup";
import customHeader from "@/components/customHeader";
import Vue from "vue";

Vue.component("pagination", pagination)
Vue.component("nProgress", nProgress)
Vue.component("loading", loading)
Vue.component("customTable", customTable)
Vue.component("searchGroup", searchGroup)
Vue.component("customHeader", customHeader)
