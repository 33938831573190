import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import api from "/src/api"
import ElementUI from 'element-ui';

import "@/utils/rem"
import '@/style/style.less'
import '@/assets/font/iconfont.css'
import '@/style/element-variables.scss'
import "@/icons/index"; // icon
import 'animate.css';
import "@/permission"
import "@/utils/direactive" // 自定义指令
import "@/components" // 自定义组件
import "@/mixin/mixin"
import tool from "@/utils"
import watermark from './utils/watermark.js'

import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-tiptap/lib/index.css';

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
    lang: "zh",
    // spellcheck: false,
});

Vue.prototype.$watermark = watermark

Vue.use(ElementUI);
Vue.prototype.$api = api
Vue.prototype.$tool = tool
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
