import request from "@/utils/requests"

function pageNotice({page = 1, pageSize = 10}){
    return request.request({
        url: "/notice/list",
        method: "get",
        params: {
            page,
            pageSize,
        }
    })
}

function getNotice(id){
    return request.request({
        url: "/notice/detail",
        method: "get",
        params: {
            id
        }
    })
}

function addNotice({icon, path, name, type, sort, content}){
    return request.request({
        url: "/notice/add",
        method: "post",
        data: {
            icon, path, name, type, sort, content
        }
    })
}

function editNotice({id, icon, path, name, type, sort, content}){
    return request.request({
        url: "/notice/edit",
        method: "post",
        data: {
            id, icon, path, name, type, sort, content
        }
    })
}

function pullNotice(id){
    return request.request({
        url: "/notice/pull",
        method: "put",
        params: {
            id
        }
    })
}

function pushNotice(id){
    return request.request({
        url: "/notice/push",
        method: "put",
        params: {
            id
        }
    })
}

function deleteNotice(id){
    return request.request({
        url: "/notice/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export default {
    pageNotice,
    addNotice,
    editNotice,
    pullNotice,
    pushNotice,
    getNotice,
    deleteNotice

}
