<template>
  <div class="header" :style="style">
    <div class="before"></div>
    <div class="title">{{ title }}</div>
    <div class="other">
      <slot></slot>
    </div>
    <div class="close" @click="goBack()">
      <svg-icon icon-class="back" />
    </div>
  </div>
</template>

<script>
export default {
  name: "customHeader",
  props: {
    title: {
      type: String,
      default: ""
    },
    line: {
      type: Boolean,
      default: true
    }
  },
  computed:{
    style() {
      return this.line?`
        border-bottom: 1px solid #E8EDF7;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      `:`
        border-radius: 8px;
      `
    }
  },
  created() {
  }
}
</script>

<style scoped lang="less">
@import "/src/style/variables";
.header{
  height: 56px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 60;
  .before{
    width: 12px;
    height: 14px;
    background: @--default-bg-color;
    border-radius: 100px 0px 100px 0px;
    margin: 0 12px 0 24px;
  }
  .title{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0,0.88);
    line-height: 24px;
  }
  .other{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .close{
    width: 20px;
    margin-right: 24px;
    margin-left: 24px;
    cursor: pointer;
  }
}
</style>
