import {MessageBox} from 'element-ui';
import {Message} from 'element-ui';
import store from "@/store";
import * as mapping from "@/utils/mapping";

function fDou(fn, delay = 600) {
    //console.log(fn);
    // 记录上一次的延时器
    let timer = null;
    return function () {
        clearTimeout(timer);
        timer = setTimeout(fn, delay);
    };
}

function throttle(fn, delay = 600){
    let pre = 0;
    return function () {
        let context = this;
        let args = arguments
        let now = new Date();
        if (now - pre > delay){
            fn.apply(context,args);
            pre = now;
        }
    }
}

function resetPixel(size) {
    let width = store.getters.clientBody
    if (width < 1200) {
        width = 1200;
    }else if (width > 1440) {
        width = 1440
    }
    // width = 1440
    return (size * width / 1440).toFixed(5)
}

function toast(callback, {
    msg = "确定删除信息?",
    type = '',
    title = "提示",
    confirmText = "确定",
    cancelText = "取消",
    showCancelButton = true,
    dangerouslyUseHTMLString = true
}) {
    return new Promise((resolve, reject) => {
        MessageBox.confirm(msg, title, {
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton,
            type,
            dangerouslyUseHTMLString
        }).then(() => {
            callback()
            resolve(true)
        }).catch(() => {
            Message.info({
                type: 'info',
                message: '已取消'
            });
            reject(false)
        });
    })
}


function toast2({
                    msg = "确定删除信息?",
                    type = '',
                    title = "提示",
                    confirmText = "确定",
                    cancelText = "取消",
                    showCancelButton = true,
                    dangerouslyUseHTMLString = true
                }) {
    return new Promise((resolve, reject) => {
        MessageBox.confirm(msg, title, {
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            showCancelButton,
            type,
            dangerouslyUseHTMLString
        }).then(() => {
            resolve(true)
        }).catch(() => {
            Message.info({
                type: 'info',
                message: '已取消'
            });
            reject(false)
        });
    })
}


function message($msg, $type = "error"){
    Message({
        message: $msg,
        type: $type,
        duration: 5 * 1000
    })
    if($type === "error") throw new Error($msg)
}

function parseTime(time, cFormat) {
    let date
    const format = cFormat || '{y}/{m}/{d}'
    if (!time) {
        return ""
        // date = new Date()
    } else {
        if (isNaN(time)) time = time.replace("T", " ").trim()
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string')) {
                if ((/^[0-9]+$/.test(time))) {
                    // support "1548221490638"
                    time = parseInt(time)
                } else {
                    // support safari
                    time = time.replace(new RegExp(/-/gm), '/')
                }
            }

            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    return format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
    })
}

function strToTimestamp(dateStr = '') {
    if (dateStr) {
        dateStr = dateStr.substring(0, 19);
        dateStr = dateStr.replace(/-/g, '/');
        return new Date(dateStr).getTime();
    } else {
        return new Date().getTime();
    }

}

function loanStatus(status = 0) {
 return status ===0?"未放款":"已放款"

}


function hasPermission(permission) {
    return !(store.getters.permissionButtons.indexOf(permission) === -1 && store.getters.permissionMenus.indexOf(permission) === -1);

}

function splitArr(arr, num = 1) {
    let result = []
    for (let i = 0; i < arr.length; i += num) {
        result.push(arr.slice(i, i + num))
    }
    return result
}

function fillArr(arr, obj, num = 1) {
    for (let i = 0; i < num; i ++) {
        arr.push(obj)
    }
}

function filterFiled(arr, location) {

    return arr

}


function formatAmt(val) {
    return "￥" + (val ? val.toLocaleString() : 0)
}

function sortByField(arr = [], field){
    let newArr = [...arr]
    newArr.sort((x, y)=>{
        return x[field] - y[field]
    })
    return newArr
}

function formatPeriod(val){
    return val ? "第" + val + "期" : "整笔"
}

function getHost(){
    return location.protocol + "//" + location.host
}

function getFileContentType(fileName){
    fileName = fileName.split("?")[0]
    if(fileName){
        let ext = fileName.substring(fileName.lastIndexOf('.') + 1)
        let extMap = {
            "pdf": "application/pdf",
            "docx": "application/msword",
            "mp4": "video/mp4",
        }
        return extMap[ext]?extMap[ext]:"image/png"
    }
    return ""

}

function blob2String(res){
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsText(res, "utf-8")
        reader.onload = function (e){
            resolve(JSON.parse(reader.result))
        }
    })
}

function hex2rgb(hex){
    let sColor = hex.toLowerCase();
    //十六进制颜色值的正则表达式
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 如果是16进制颜色
    if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
            let sColorNew = "#";
            for (let i=1; i<4; i+=1) {
                sColorNew += sColor.slice(i, i+1).concat(sColor.slice(i, i+1));
            }
            sColor = sColorNew;
        }
        //处理六位的颜色值
        let sColorChange = [];
        for (let i=1; i<7; i+=2) {
            sColorChange.push(parseInt("0x"+sColor.slice(i, i+2)));
        }
        return sColorChange.join(",")
    }
    return sColor;
}

function goAnchor(val){
    document.getElementById(val).scrollIntoView()
}

function countArr(arr, field) {
    let hash = {};
    arr.forEach((item) => {
        if(!hash[item[field]]){
            hash[item[field]]=1;
        }
        else{
            hash[item[field]]++;
        }
    });
    return hash;
}


export default {
    fDou,
    throttle,
    resetPixel,
    toast,
    parseTime,
    strToTimestamp,
    hasPermission,
    splitArr,
    filterFiled,
    formatAmt,
    sortByField,
    getHost,
    getFileContentType,
    blob2String,
    hex2rgb,
    loanStatus,
    formatPeriod,
    fillArr,
    goAnchor,
    countArr,
    message,
    toast2,
    ...mapping
}
