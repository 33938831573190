import { render, staticRenderFns } from "./amountRange.vue?vue&type=template&id=50fc4b68&scoped=true&"
import script from "./amountRange.vue?vue&type=script&lang=js&"
export * from "./amountRange.vue?vue&type=script&lang=js&"
import style0 from "./amountRange.vue?vue&type=style&index=0&id=50fc4b68&prod&scoped=true&lang=less&"
import style1 from "./amountRange.vue?vue&type=style&index=1&id=50fc4b68&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50fc4b68",
  null
  
)

export default component.exports